import React from 'react';

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';

interface ICredits {
  show: boolean;
  close: () => void;
}

const Credits: React.FC<ICredits> = props => {
  return (
    <IonModal isOpen={props.show} onDidDismiss={props.close}>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle className="ion-text-left">
            CREDITI
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={props.close}>
              <IonIcon icon={closeOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="dark">
        <IonGrid>
          <IonRow className="ion-margin-top">
            <IonCol size="12" className="ion-text-center">
              <IonText className="ion-margin-bottom" color="light">
                <h4>Concept, design e sviluppo</h4>
              </IonText>

              <a href="https://mezzoforte.design" target="_blank" rel="noopener noreferrer">
                <IonImg src="/assets/img/Logo_MezzoForte_white.svg" style={{ width: '130px', margin: '10px auto' }} />
              </a>
            </IonCol>

            <hr style={{ backgroundColor: 'var(--ion-color-light)', width: '90%', margin: '1.5rem auto' }} />

            <IonCol size="12" className="ion-text-center">
              <IonText className="ion-margin-bottom" color="light">
                <h4>Patrocinato da</h4>
              </IonText>
            </IonCol>

            <IonCol size="6" className="ion-text-center">
              <a href="https://www.comune.follonica.gr.it/" target="_blank" rel="noopener noreferrer">
                <IonImg src="/assets/img/stemma-comune-follonica.png" style={{ width: '100px', margin: '10px auto' }} />
              </a>

              <IonText color="medium">
                <h5 className="superbold">Comune di Follonica</h5>
              </IonText>
            </IonCol>

            <IonCol size="6" className="ion-text-center">

              <a href="https://www.cantiereculturafollonica.it/" target="_blank" rel="noopener noreferrer">
                <IonImg src="/assets/img/cantiere-cultura-white.png" style={{ width: '100px', margin: '10px auto' }} />
              </a>

              <IonText color="medium">
                <h5 className="superbold">Cantiere Cultura</h5>
              </IonText>
            </IonCol>

          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default Credits;