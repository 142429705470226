import { useEffect, useRef } from 'react';

/*
 * Exploits the useEffect hook to define a custom hook that 
 * executes a function only once, after the inital render!
 */
export const useMountEffect = (funct: () => void) => useEffect(funct, []);

/*
 * Keeps the previous value of a state. Usage example:
 * const [count, setCount] = useState(0);
 * const prevCount = usePrevious(count);
 * console.log(`Now: ${count}, before: ${prevCount}`);
 */
export const usePrevious = (value: any) => {
  const ref = useRef<any>();
  useEffect(() => { ref.current = value }, [value]);
  return ref.current;
};

export const useAnimationFrame = (callback: (deltaTime: number) => any) => {

  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef<any>(null);
  const previousTimeRef = useRef<any>(undefined);
  
  const animate = (time: any) => {

    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime)
    }

    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }
  
  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []); // Make sure the effect runs only once
}