import React, { useState, useContext } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  IonText,
  IonButtons,
  IonToast
} from '@ionic/react';

import {
  micOutline,
  informationOutline,
  volumeHighOutline
} from 'ionicons/icons';

import Credits from '../components/Credits';
import Introduction from '../components/Introduction';

/* Data */
import AppContext from '../data/app-context';

// import ExploreContainer from '../components/ExploreContainer';
import './Home.css';

const Home: React.FC = () => {

  const appCtx = useContext(AppContext);

  const [showCredits, setShowCredits] = useState<boolean>(false);
  const openCredits = () => setShowCredits(true);
  const closeCredits = () => setShowCredits(false);

  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const [toasMessage, setToasMessage] = useState<string>('');

  const onEnter = (options: any) => {
    appCtx.setPositionAccess(options.permission);
    appCtx.setShowIntro(false);

    options.permission ?
      setToasMessage('Posizione ottenuta con successo. Benvenuto!') :
      setToasMessage('Posizione non disponibile. Non puoi caricare nuovi suoni ma puoi accedere alla sezione ASCOLTA');

    if (options.showToast) {
      setToastOpen(true);
    }
  };

  return (
    <React.Fragment>

      { appCtx.getShowIntro() ? <Introduction onEnter={onEnter} /> : null }

      <Credits show={showCredits} close={closeCredits} />

      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton size="large" onClick={openCredits}>
                <IonIcon slot="icon-only" icon={informationOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle>Follonica | Voci della tua Città</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>

          <IonToast
            isOpen={toastOpen}
            onDidDismiss={() => setToastOpen(false)}
            message={toasMessage}
            duration={4000}
          />

          <IonGrid id="main-grid">
            <IonRow className="ion-text-center ion-justify-content-center ion-align-items-center">

              <IonCol size="12" size-lg="6">
                <div className="home-btn-container">
                  <IonButton
                    id="listen-fab"
                    className="round-btn-large"
                    routerLink='/listen'
                    routerDirection="forward"
                    size="large"
                    shape="round"
                  >
                    <IonIcon icon={volumeHighOutline}></IonIcon>
                  </IonButton>
                  <IonText className='ion-margin-top'>
                    <h3>ASCOLTA</h3>
                  </IonText>
                </div>
              </IonCol>

              <IonCol size="12" size-lg="6">
                <div className="home-btn-container">
                  <IonButton
                    id="record-fab"
                    className="round-btn-large"
                    routerLink='/record'
                    routerDirection="forward"
                    size="large"
                    shape="round"
                    disabled={!appCtx.getPositionAccess()}
                  >
                    <IonIcon icon={micOutline}></IonIcon>
                  </IonButton>
                  <IonText className='ion-margin-top'>
                    <h3 className={`${appCtx.getPositionAccess() ? '' : 'disabld-text'}`}>REGISTRA</h3>
                  </IonText>
                </div>

              </IonCol>

            </IonRow>
          </IonGrid>

          <div className="powered-by dark">
            <small>
              Powered by <a href="https://mezzoforte.design" target="_blank" rel="noopener noreferrer">Mezzo Forte</a>
            </small>
          </div>

        </IonContent>
      </IonPage>
    </React.Fragment>
  );
};

export default Home;
