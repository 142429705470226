import { Device } from './device';

export const getGeoPosition = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(position => {
      resolve(position);
    }, error => reject(error), Device.geolocationOpts);
  });
};

export const alreadyGranted = () => {
  return new Promise((resolve, reject) => {
    navigator.permissions.query({ name:'geolocation' })
    .then(result => result.state === 'granted' ? resolve(result.state) : reject(result.state))
    .catch(err => reject(err));
  });
};

export const getGeoPermissionState = () => {
  return new Promise((resolve, reject) => {
    navigator.permissions.query({ name:'geolocation' })
    .then(result => resolve(result.state))
    .catch(err => reject(err));
  });
};