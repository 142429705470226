import React from 'react';

import './SpotPopup.css';

import LoaderSpinner from '../components/LoaderSpinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

interface ISpotPopup {
  // id: number;
  // soundUrl: string;
  // activeSpot: number | null;
  state: null | string;
}

const SpotPopup: React.FC<ISpotPopup> = props => {

  return (
    <div className="spot-popup-content">

      {
        props.state === 'loading' ?
          <LoaderSpinner
            type="Puff"
            color="var(--ion-color-dark)"
            text="CARICAMENTO"
          /> : null
      }
      {
        props.state === 'playing' ?
          <LoaderSpinner
            type="Bars"
            color="var(--ion-color-tertiary)"
            text="IN RIPRODUZIONE"
          /> : null
      }
    </div>
  );
};

export default SpotPopup;