import React, { createRef, useRef, useState } from 'react';
import { useMountEffect, usePrevious } from '../hooks/toolkit';

import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';

import SpotPopup from './SpotPopup';

import { Howl } from 'howler';

import 'leaflet/dist/leaflet.css';
import './SoundsMap.css';
import { getAudioBlobURL } from '../utils/request';

interface IPos {
  lat: number;
  lon: number;
}

export interface ISoundSpot {
  filetype  : string;
  idFile    : number;
  latitude  : number;
  longitude : number;
}

interface ISoundsMap {
  spots  : ISoundSpot[];
  height : number;
  center : IPos;
}

const SoundsMap: React.FC<ISoundsMap> = props => {
  /*
   * woraround for correctly get PNG markers from CSS
   * @see https://github.com/PaulLeCam/react-leaflet/issues/453
   */
  useMountEffect(() => {
    const L = require("leaflet");
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });
  });


  const [activeSound, setActiveSound] = useState<Howl | null>(null);
  const prevActiveSound = usePrevious(activeSound);

  const [playerState, setPlayerState] = useState<null | string>(null);

  const popupRefs = useRef<any>([createRef(), createRef()]);

  const markerEventHandler: any = {
    click: async (e: any) => {

      // get clicked sound info
      const idFile = e.target.options['data-idFile'];
      const filetype = e.target.options['data-filetype'];

      const audioBlobURL: any = await getAudioBlobURL({ idFile });

      // TODO manage if user closes poput while waiting for audio blob

      if (!audioBlobURL) {
        return;
      }

      // load sound
      setPlayerState('loading');

      const format = filetype === 'audio/mpeg' ? 'mp3' : 'wav';

      const sound = new Howl({
        src      : [audioBlobURL],
        format   : [format],
        html5    : true,
        autoplay : false,
        onend: () => {
          // close marker popup
          popupRefs.current.forEach((ref: any) => {
            if (!ref.current || !ref.current._closeButton) {
              return;
            }
            ref.current._closeButton.click();
          });

          setPlayerState(null);
          setActiveSound(null);
        }
      });

      sound.once('load', () => {
        // set active sound
        setActiveSound(sound);
        // play sound
        sound.play();

        if (prevActiveSound) {
          prevActiveSound.stop();
          prevActiveSound.unload();
        }

        setPlayerState('playing');
      });
    }
  };

  const handlePopupClose = () => {
    if (!activeSound || !activeSound.playing()) {
      return;
    }

    // fade out sound
    activeSound.once('fade', () => {
      activeSound.unload();
      setPlayerState(null);
      setActiveSound(null);
    });

    activeSound.fade(1, 0, 1000);
  };

  return (
    <div id="map-outer-box">
      <MapContainer
        center={[props.center.lat, props.center.lon]}
        zoom={12}
        maxZoom={19}
        tap={false}
        scrollWheelZoom={false}
        style={{ height: `${props.height}px` }}
        id={activeSound ? 'active-listen-map' : 'listen-map'}
      >
        <TileLayer
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
          maxZoom={20}
        />
        {
          props.spots.map((spot, idx) => (
            <Marker position={[spot.latitude, spot.longitude]} key={spot.idFile} data-idFile={spot.idFile} data-filetype={spot.filetype} eventHandlers={markerEventHandler}>
              <Popup className="spot-popup" onClose={handlePopupClose} ref={popupRefs.current[idx]}>
                <SpotPopup state={playerState} />
              </Popup>
            </Marker>
          ))
        }
      </MapContainer >
    </div>
  );
};

export default SoundsMap;