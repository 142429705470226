import { IonText } from '@ionic/react';

import React from 'react';

import Loader from 'react-loader-spinner';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

type LoaderTypes =
    | 'Audio'
    | 'BallTriangle'
    | 'Bars'
    | 'Circles'
    | 'Grid'
    | 'Hearts'
    | 'MutatingDots'
    | 'None'
    | 'NotSpecified'
    | 'Oval'
    | 'Plane'
    | 'Puff'
    | 'RevolvingDot'
    | 'Rings'
    | 'TailSpin'
    | 'ThreeDots'
    | 'Triangle'
    | 'Watch';

interface ILoaderSpinner {
  color: string;
  text: string;
  type: LoaderTypes;
}

const LoaderSpinner: React.FC<ILoaderSpinner> = props => {
  return (
    <React.Fragment>
      <Loader
        type={props.type}
        color={props.color}
        height={100}
        width={100}
      />
      <IonText>
        <p>{props.text}</p>
      </IonText>
    </React.Fragment>
  )
};

export default LoaderSpinner;