import React, { useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
  useIonViewDidEnter
} from '@ionic/react';

import { informationOutline } from 'ionicons/icons';

import { useMountEffect } from '../hooks/toolkit';
import { getJSON } from '../utils/request';

import Credits from '../components/Credits';
import SoundsMap, { ISoundSpot } from '../components/SoundsMap';

import LoaderSpinner from '../components/LoaderSpinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import './Listen.css';

import { FOLLONICA_CENTER_LAT, FOLLONICA_CENTER_LON, RADIUS } from '../utils/constants';

const Listen: React.FC = () => {

  const [showCredits, setShowCredits] = useState<boolean>(false);
  const openCredits = () => setShowCredits(true);
  const closeCredits = () => setShowCredits(false);

  const [loadingSpots, setLoadingSpots] = useState<boolean>(true);
  const [soundSpots, setSoundSpots] = useState<ISoundSpot[]>([]);

  const [mapHeight, setMapHeight] = useState<number>(0);
  
  useIonViewDidEnter(() => {
    /* 
     * trigger a 'window-resize' event when Page has finished, 
     * rendering and animating, so leaflet map can read a 
     * consistent height value
     */
    window.dispatchEvent(new Event('resize'));

    // calc available space for map
    const mainCol = document.getElementById('listen-page-grid');
    // setMapHeight(mainCol!.offsetHeight - mainCol!.offsetHeight / 10);
    setMapHeight(mainCol!.offsetHeight - 8);
  });

  useMountEffect(() => {    
    // get spots
    getJSON(`/api/get/fileMetadata/position?radius=${RADIUS}&lat=${FOLLONICA_CENTER_LAT}&lon=${FOLLONICA_CENTER_LON}`).then(res => {

      if (res.error === 'no_audio') {
        setSoundSpots([]);
        setLoadingSpots(false);
        return;
      }

      const spots = res.map((el: any)  => ({
        filetype  : el.filetype,
        idFile    : el.id_file,
        latitude  : el.position.x,
        longitude : el.position.y
      }));

      setSoundSpots(spots);
      setLoadingSpots(false);
    });
  });

  return (
    <React.Fragment>
      <Credits show={showCredits} close={closeCredits} />
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref='/' />
            </IonButtons>
            <IonTitle>Mezzo Forte | Voices App</IonTitle>
            <IonButtons slot="end">
              <IonButton size="large" onClick={openCredits}>
                <IonIcon slot="icon-only" icon={informationOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonGrid id="listen-page-grid">
            <IonRow className="ion-text-center ion-justify-content-center ion-align-items-center">
              <IonCol id="listen-page-col">
                {
                  loadingSpots ?
                    <LoaderSpinner
                      type="Puff"
                      color="var(--ion-color-dark)"
                      text="Mappa sonora in caricamento"
                    /> :
                    <SoundsMap
                      spots={soundSpots}
                      height={mapHeight}
                      center={{lat: FOLLONICA_CENTER_LAT, lon: FOLLONICA_CENTER_LON}}
                    />
                }
              </IonCol>
            </IonRow>
          </IonGrid>

        </IonContent>
      </IonPage>
    </React.Fragment>
  );
};

export default Listen;
