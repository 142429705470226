import React, { useRef, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
  IonLabel,
  isPlatform,
  useIonViewDidEnter,
  IonImg,
  IonText,
  IonAlert
} from '@ionic/react';

import { homeOutline, informationOutline, micOutline } from 'ionicons/icons';

// import { ReactMic, AudioContext } from '@mezzo-forte/react-mic';
import { ReactMic } from '@cleandersonlobo/react-mic';
// import { ReactMic } from 'react-mic';

import Credits from '../components/Credits';
import RecordButton from '../components/RecordButton';
import PlaybackPanel from '../components/PlaybackPanel';

import LoaderSpinner from '../components/LoaderSpinner';
import { getJSON, uploadAudioFile } from '../utils/request';
import { getGeoPosition } from '../utils/geolocation';
// import { useAnimationFrame } from '../hooks/toolkit';

import './Record.css';
import { useMountEffect } from '../hooks/toolkit';
import { Device } from '../utils/device';

const MAX_REC_TIME = 4e4; // 40000 ms (40 s)
// const MAX_REC_TIME = 5000; // 40000 ms (40 s)

const Record: React.FC = () => {

  const [showCredits, setShowCredits] = useState<boolean>(false);

  const [tooFarAlert, setTooFarAlert] = useState<string>('');

  const [recBlobURL, setRecBlobURL] = useState<string>('');
  const [recBlob, setRecBlob] = useState<any>(null);
  const [record, setRecord] = useState<boolean>(false);

  const [recordButtonDisabled, setRecordButtonDisabled] = useState<boolean>(false);

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUploaded, setIsUploaded] = useState<boolean>(false);

  const [useSafariAlert, setUseSafariAlert] = useState<boolean>(false);

  const reactMicRef = useRef<any>(null);

  const openCredits = () => setShowCredits(true);
  const closeCredits = () => setShowCredits(false);

  useIonViewDidEnter(() => {
    // hide soundwave canvas on iOS, because is not working!
    // @see https://github.com/cleandersonlobo/react-mic/issues/26
    if (reactMicRef.current.state.canvas && isPlatform('ios')) {
      reactMicRef.current.state.canvas.classList.add('visibility-hidden')
    }
  });

  useMountEffect(() => {
    if (Device.isiOS() && !Device.isSafariiOS() && parseInt(Device.osVersion) < 14) {
      setUseSafariAlert(true);
    }

    getGeoPosition()
      .then((position: any) => {
        const { latitude, longitude } = position.coords;
        getJSON(`/api/get/allowedPosition?lat=${latitude}&lon=${longitude}`)
          .then(async res => {
            console.log(res);
            // target.cityPosition
            if (!res.allowed) {
              setRecordButtonDisabled(true);
              setTooFarAlert(res.message);
            }
          })
          .catch(async error => {
            const parsedError = await error.json();
            console.error(parsedError);
          });
      });
  });

  const onStop = (blob: any) => {
    console.log('stop', blob);
    setRecBlob(blob);
    setRecBlobURL(blob.blobURL);
  };

  const resetState = () => {
    setRecBlobURL('');
    setRecBlob(null);
    setIsUploading(false);
    setIsUploaded(false);
  };

  const cancelRecording = () => {
    resetState();
  };

  const uploadRecording = async () => {

    setIsUploading(true);

    const position: any = await getGeoPosition();

    try {
      const options = {
        filename: `${position.coords.latitude}-${position.coords.longitude}-${new Date().getTime()}`,
        filetype: recBlob?.blob.type,
        length: (recBlob?.stopTime - recBlob?.startTime) / 1000,
        lat: position.coords.latitude,
        lon: position.coords.longitude,
        blob: recBlob?.blob
      };

      const res = await uploadAudioFile(options);

      setIsUploading(false);
      setIsUploaded(true);

    } catch (error) {
      // log error!
      console.error(error);

      const parsedError = await error.json()
      alert(parsedError?.error);

      resetState();
    }

  };

  const onData = (data: any) => {
    // console.log('data', data);
  };

  const onStart = () => {
    // console.log('actual recording starte!');
    setRecordButtonDisabled(false);
  }

  const onBlock = () => {
    console.log('block!')
    alert(`Hai negato l'accesso al microfono. Non potrai registrare e caricare il tuo suono`);
  }

  // const [inputMeter, setInputMeter] = useState<number>(0);
  // const analyser = useRef<any>(null);
  // const sampleBuffer = useRef<any>(null);

  // useAnimationFrame(delta => {
  //   if (!sampleBuffer.current) {
  //     return;
  //   }
  // OPTION 1
  // analyser.getByteTimeDomainData(sampleBuffer.current);
  // let squareSum = 0;
  // for (let i = 0; i < sampleBuffer.current.length; i++) {
  //   const shifted = sampleBuffer.current[i] - 128;
  //   const squared = Math.pow(shifted, 2);
  //   squareSum += squared;
  // }
  // const root = Math.sqrt(squareSum);
  // const rms = root + 128;
  // setInputMeter(rms);

  // OPTION 2
  // analyser.getByteTimeDomainData(sampleBuffer.current);
  // let peak = 0;
  // for (let i = 0; i < sampleBuffer.current.length; i++) {
  //   const abs = Math.abs(sampleBuffer[i]);
  //   if (abs > peak) {sampleBuffer.current
  //     peak = abs;
  //   }
  // }
  // setInputMeter(peak);

  // OPTION 3
  // analyser.current.getByteTimeDomainData(sampleBuffer.current);
  // const median = sampleBuffer.current[Math.floor(analyser.current.frequencyBinCount / 2)] / 128.0 / 2;
  // const fixed = median.toFixed(2);
  // setInputMeter(fixed);
  // });

  const handleRecord = () => {
    // if (!record) {
    //   AudioContext.initAnalyzer();
    //   analyser.current = AudioContext.getAnalyser();
    //   analyser.current.fftSize = 2048;
    //   const bufferLength = analyser.current.frequencyBinCount;

    //   const dataArray = new Uint8Array(bufferLength);
    //   sampleBuffer.current = dataArray;
    // }

    // reactMicRef.current.state.canvas.classList.toggle('visibility-hidden');

    if (!record) {
      // when starting, disable button since recording is really started, to avoid user's double clicks
      setRecordButtonDisabled(true);
    }

    setRecord(!record);
  }

  const renderListenPanel = () => {
    return (
      <PlaybackPanel
        audioURL={recBlobURL}
        onCancel={cancelRecording}
        onConfirm={uploadRecording}
      />
    );
  };

  const renderRecordButton = () => {
    return (
      <RecordButton
        recording={record}
        disabled={recordButtonDisabled}
        maxRecTime={MAX_REC_TIME}
        handleRecord={handleRecord}
        handleComplete={() => setRecord(false)}
      />
    )
  };

  return (
    <React.Fragment>
      <Credits show={showCredits} close={closeCredits} />
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref='/' />
            </IonButtons>
            <IonTitle>Mezzo Forte | Voices App</IonTitle>
            <IonButtons slot="end">
              <IonButton size="large" onClick={openCredits}>
                <IonIcon slot="icon-only" icon={informationOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonAlert
            isOpen={useSafariAlert}
            onDidDismiss={() => setUseSafariAlert(false)}
            cssClass='my-custom-class'
            header={'UTILIZZA SAFARI PER REGISTRARE'}
            message={`La tua versione di iOS non consente a questo browser di accedere al microfono. Utilizza Safari per registrare un suono :)`}
            buttons={[
              {
                text: 'CAPITO',
                role: 'cancel',
                cssClass: 'dark-alert-button'
              }
            ]}
          />

          <IonAlert
            isOpen={!!tooFarAlert}
            onDidDismiss={() => setTooFarAlert('')}
            cssClass='too-far-alert'
            message={tooFarAlert}
            buttons={[
              {
                text: 'CAPITO',
                role: 'cancel',
                cssClass: 'dark-alert-button'
              }
            ]}
          />

          <IonGrid id="main-grid" className={record ? `record-grid recording` : 'record-grid'}>
            <IonRow className="ion-text-center ion-justify-content-center ion-align-items-center">
              <IonCol className="ion-text-center ion-justify-content-center ion-align-items-center">

                {
                  (!isUploading && !isUploaded) ? (recBlobURL === '' ? renderRecordButton() : renderListenPanel()) : null
                }

                {
                  recBlobURL === '' ? null :
                    (isUploading ?
                      <div className="flex-vert-center full-width">
                        <LoaderSpinner
                          type="Puff"
                          color="var(--ion-color-dark)"
                          text="CARICAMENTO..."
                        />
                      </div>
                      : null)
                }
                {
                  recBlobURL === '' ? null :
                    (isUploaded ?
                      <React.Fragment>
                        <IonGrid>
                          <IonRow>
                            <IonCol size="12">
                              <IonImg id="checkmark" src="/assets/img/checkmark.svg"></IonImg>
                            </IonCol>
                            <IonCol size="12">
                              <IonLabel>Caricamento effettuato con successo!</IonLabel>
                            </IonCol>
                            <IonCol size="6">
                              <div className="home-btn-container ion-margin-top">
                                <IonButton
                                  className="round-btn-small"
                                  routerLink='/'
                                  color="dark"
                                  routerDirection="forward"
                                  size="large"
                                  shape="round"
                                >
                                  <IonIcon icon={homeOutline}></IonIcon>
                                </IonButton>
                                <IonText>
                                  <h5>TORNA ALLA HOME</h5>
                                </IonText>
                              </div>
                            </IonCol>
                            <IonCol size="6">
                              <div className="home-btn-container ion-margin-top">
                                <IonButton
                                  className="round-btn-small"
                                  onClick={resetState}
                                  color="tertiary"
                                  size="large"
                                  shape="round"
                                >
                                  <IonIcon icon={micOutline}></IonIcon>
                                </IonButton>
                                <IonText>
                                  <h5>REGISTRA UN NUOVO SUONO</h5>
                                </IonText>
                              </div>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </React.Fragment>
                      : null
                    )
                }


                <ReactMic
                  record={record}
                  ref={reactMicRef}
                  visualSetting="sinewave"
                  className="sound-wave"
                  onStop={onStop}
                  onData={onData}
                  onBlock={onBlock}
                  onStart={onStart}
                  strokeColor="#eb445a"
                  backgroundColor="#F2EFEB"
                  width={350}
                  mimeType="audio/mp3"
                  channelCount={1}
                  audioBitsPerSecond={96000}
                />

              </IonCol>
            </IonRow>

          </IonGrid>

        </IonContent>
      </IonPage>
    </React.Fragment >
  );
};

export default Record;
