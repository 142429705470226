import React from 'react';
import { IonButton, IonCol, IonGrid, IonIcon, IonLabel, IonRow, IonText } from '@ionic/react';

import Player from "react-howler-player"
import { cloudUploadOutline, trashOutline } from 'ionicons/icons';

import './PlaybackPanel.css';

interface IPlaybackPanel {
  audioURL: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const PlaybackPanel: React.FC<IPlaybackPanel> = props => {

  return (
    <React.Fragment>
      <div className="flex-vert-center">

        <IonText className='ion-margin-top ion-margin-bottom'>
          <h3>RIASCOLTA</h3>
        </IonText>

        <Player
          className="asdf"
          src={[props.audioURL]}
          profile="top_progress"
        />

        <IonGrid className='ion-margin-top full-width'>
          <IonRow>
            <IonCol size="6">

              <div className="flex-vert-center">
                <IonButton
                  color="dark"
                  className="round-btn-medium"
                  onClick={props.onCancel}
                  shape="round"
                  size="large"
                >
                  <IonIcon icon={trashOutline}></IonIcon>

                </IonButton>
                <IonLabel>ANNULLA</IonLabel>
              </div>
            </IonCol>

            <IonCol size="6">

            <div className="flex-vert-center">
                <IonButton
                  className="round-btn-medium"
                  color="success"
                  onClick={props.onConfirm}
                  shape="round"
                  size="large"
                >
                  <IonIcon icon={cloudUploadOutline}></IonIcon>

                </IonButton>
                <IonLabel>INVIA</IonLabel>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </React.Fragment>
  )
}

export default PlaybackPanel;