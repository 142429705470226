import Bowser from "bowser";

export class Device {
  static webaudio() {
    const wind: any = window;
    return Boolean(wind.AudioContext || wind.webkitAudioContext
      || wind.mozAudioContext || wind.oAudioContext || wind.msAudioContext);
  }

  static geolocation() {
    return Boolean("geolocation" in navigator);
  }

  static isSupported() {
    return this.webaudio() && this.geolocation();
  }

  static get geolocationOpts() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const geolocationOpts = {
      enableHighAccuracy : Boolean(browser.getBrowserName() !== 'Firefox'),
      maximumAge         : 30000,
      timeout            : 27000
    };
    return geolocationOpts;
  }

  static isTouch() {
    // TODO
  }

  static isMobile() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getPlatformType() === 'mobile';
  }

  static isDesktop() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getPlatformType() === 'desktop';
  }

  static isChrome() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowserName() === 'Chrome';
  }

  static isFF() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowserName() === 'Firefox';
  }

  static isSafari() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return Boolean(browser.getBrowserName() === 'Safari');
  }

  static isiOS() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return Boolean(browser.getOSName() === 'iOS');
  }

  static isSafariiOS() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return Boolean(browser.getOSName() === 'iOS' && browser.getBrowserName() === 'Safari');
  }

  static isFacebookApp() {
    const wind: any = window;
    const ua = navigator.userAgent || navigator.vendor || wind.opera;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
  }

  static get os() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getOSName();
  }

  static get browser() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowserName();
  }

  static get browserVersion() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowserVersion();
  }

  static get platformType() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getPlatformType();
  }

  static get platform() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getPlatform();
  }

  static get osVersion() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getOSVersion();
  }
}

