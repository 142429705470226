import React, { useEffect, useRef, useState } from 'react';
import {
  IonButton,
  IonIcon,
  IonText
} from '@ionic/react';
import { micOutline } from 'ionicons/icons';

import Countdown from 'react-countdown';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import RecDot from './RecDot';

interface IRecordButton {
  maxRecTime: number; // ms
  recording: boolean;
  disabled: boolean;
  handleRecord: () => void;
  handleComplete: () => void;
}

const RecordButton: React.FC<IRecordButton> = props => {

  const countRef = useRef<any>(null);
  const [countdownDate, setCountdownDate] = useState<number>(Date.now() + props.maxRecTime);
  const [key, setKey] = useState<number>(0);

  useEffect(() => {
    if (countRef === null) {
      return;
    }

    if (props.recording) {
      setCountdownDate(Date.now() + props.maxRecTime);
      countRef.current?.start();
    } else {
      countRef.current?.stop();
      setKey(prevKey => prevKey + 1)
    }

  }, [props.recording]);

  const renderCountdown = () => {
    return (
      <div className='ion-margin-top'>

        <Countdown        
          ref={countRef}
          date={countdownDate}
          autoStart={false}
          renderer={props => <span className="monospace">{props.formatted.minutes}:{props.formatted.seconds}</span>}
          onComplete={props.handleComplete}
          onStart={() => console.log('CONTDOWN START!')}
          intervalDelay={20}
        />

      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="flex-vert-center">
        {props.recording ?
          <React.Fragment>
            <IonText className='ion-margin-top'>
              <h3> <RecDot />  REGISTRAZIONE...</h3>
            </IonText>
            <IonText className='ion-margin-bottom'>
              <p><i>PREMI PER INTERROMPERE</i></p>
            </IonText>
          </React.Fragment>
          :
          <React.Fragment>
            <IonText className='ion-margin-top'>
              <h3>REGISTRA IL TUO SUONO</h3>
            </IonText>
            <IonText className='ion-margin-bottom'>
              <p><i>PREMI UNA VOLTA PER INIZIARE</i></p>
            </IonText>
          </React.Fragment>
        }

        <CountdownCircleTimer
          key={key}
          isPlaying={props.recording}
          duration={(props.maxRecTime / 1000)}
          onComplete={props.handleComplete}
          size={150}
          strokeLinecap='square'
          colors={'#E0BE47'}
        >
          {({ remainingTime }) => (
            <IonButton
              className={props.recording ? 'round-btn-large recording' : 'round-btn-large'}
              size="large"
              shape="round"
              disabled={props.disabled}
              onClick={props.handleRecord}
            >
              <IonIcon icon={micOutline}></IonIcon>
            </IonButton>
          )}
        </CountdownCircleTimer>

        {renderCountdown()}

      </div>
    </React.Fragment>
  );
};

export default RecordButton;