/**
 * @function postJSON POST request using Fetch API for JSON data
 * @param  {String}  [url=''] API URL where to post
 * @param  {Object}  [data={}] JSON data for POST body
 * @return {Promise} pending promise
 */
export const postJSON = async (url = '', data = {}) => {
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data)
    });
    // check if okay
    if (!response.ok) {
      return Promise.reject(response);
    }
    // parses JSON response into native JavaScript objects
    return response.json();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postFile = async (url: string = '', blob: Blob, filename: string) => {
  try {

    const form = new FormData();
    form.append('file_audio', blob, filename);

    const response = await fetch(url, {
      method      : 'POST', // *GET, POST, PUT, DELETE, etc.
      body           : form
    });
    // check if okay
    if (!response.ok) {
      return Promise.reject(response);
    }
    // parses JSON response into native JavaScript objects
    return response.json();
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

/**
 * @function getJSON GET request using Fetch API for JSON data
 * @param  {String}  [url=''] API URL where to get
 * @return {Promise} pending promise
 */
export const getJSON = async (url = '') => {
  try {
    const response = await fetch(url);
    // check if okay
    if (!response.ok) {
      return Promise.reject(response);
    }
    // parses JSON response into native JavaScript objects
    return response.json();
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * @function getText GET request using Fetch API for text data
 * @param  {String}  [url=''] API URL where to post
 * @return {Promise} pending promise
 */
export const getText = async (url = '') => {
  try {
    const response = await fetch(url);
    // check if okay
    if (!response.ok) {
      return Promise.reject(response);
    }
    // parses response into string
    return response.text();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadAudioFile = (options: any = {}) => {
  return new Promise((resolve, reject) => {
    // first upload metadata
    const { blob, filename, filetype, lat, lon, length } = options;
    postJSON('/api/upload/fileMetadata', { filename, filetype, lat, lon, length })
      .then(metadata => {
        console.log('metadata ok', metadata);
        // upload file blob
        postFile(`/api/upload/fileBlob?token=${metadata.token}`, blob, filename)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }).catch(err => reject(err));
  });
};

export const getAudioBlobURL = (options: any = {}) => new Promise((resolve, reject) => {
  console.log(`fetching /api/get/fileBlob?id=${options.idFile}`);

  fetch(`/api/get/fileBlob?id=${options.idFile}`)
    .then(response => response.blob())
    .then(blob => {
      console.log('got blob', blob)
      const blobURL = window.URL.createObjectURL(blob);
      console.log('blob URL', blobURL)
      resolve(blobURL as string);
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
});