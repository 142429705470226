import React, { useState } from 'react';
import AppContext  from './app-context';

// import { setCookie, getCookie, deleteCookie } from '../utils/cookies';

const AppContextProvider: React.FC = props => {

  const [hasPositionAccess, setHasPositionAccess] = useState<boolean>(false);
  const [showIntroduction, setShowIntroduction] = useState<boolean>(true);

  const getShowIntro = () => showIntroduction;
  const setShowIntro = (val: boolean) => setShowIntroduction(val);

  const getPositionAccess = () => hasPositionAccess;
  const setPositionAccess = (val: boolean) => setHasPositionAccess(val);

  return (
    <AppContext.Provider value={{ getPositionAccess, setPositionAccess, getShowIntro, setShowIntro }}>
      {props.children}
    </AppContext.Provider>
  )
};

export default AppContextProvider;