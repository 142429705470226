import { IonAlert, IonButton, IonImg, IonSpinner, IonText } from '@ionic/react';
import React, { useState } from 'react';

import { Device } from '../utils/device';
import { getGeoPermissionState, getGeoPosition } from '../utils/geolocation';

import './Introduction.css';

interface IIntroduction {
  onEnter: (state: any) => void;
}

const Introduction: React.FC<IIntroduction> = props => {

  const [visible, setVisible] = useState<boolean>(true);

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const [entering, setEntering] = useState<boolean>(false);

  const enter = async () => {
    if (entering) {
      // if already entering, wait
      return;
    }

    setEntering(true);

    if (!Device.geolocation()) {
      // geolocation is not available
      setAlertMessage("Il tuo dispositivo non supporta la geolocalizzazione");
      setShowAlert(true);
    }

    if (Device.isiOS()) {

      getGeoPosition()
        .then(position => {
          // toast!
          props.onEnter({
            showToast: true,
            permission: true
          });
          // hide splash screen
          setVisible(false);
        }).catch(err => {
          // position denied
          alert(err.message)
          setAlertMessage("Accesso alla posizone negato.");
          setShowAlert(true);
        });

    } else {

      const geoPosState = await getGeoPermissionState();

      switch (geoPosState) {
        case 'granted':
          // already have permission -> hide splash screen
          // console.log('already have permission!');
          props.onEnter({
            showToast: false,
            permission: true
          });
          setVisible(false);
          break;
        case 'denied':
          // permission has been denied
          setAlertMessage("L'accesso alla posizone è stato negato. Controlla le impostazioni del tuo browser per abilitare l'accesso a quest'app.");
          setShowAlert(true);
          // console.log('posizione negata')
          break;
        default:
          getGeoPosition()
            .then(position => {
              // toast!
              props.onEnter({
                showToast: true,
                permission: true
              });
              // hide splash screen
              setVisible(false);
            }).catch(err => {
              // position denied
              setAlertMessage("Accesso alla posizone negato.");
              setShowAlert(true);
            });
          break;
      }
    }
  };

  return (
    <React.Fragment>

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass='my-custom-class'
        header={'POSIZIONE NON DISPONIBILE'}
        subHeader={alertMessage}
        message={`Puoi comunque entrare nell'app. Potrai ascoltare ma non registrare.`}
        buttons={[
          {
            text: 'VA BENE',
            cssClass: 'dark-alert-button',
            handler: () => {
              props.onEnter({
                showToast: true,
                permission: false
              });
              setVisible(false);
            }
          }
        ]}
      />

      {visible ?
        (<div className="full-splash introduction-splash">
          <IonImg id="mf-logo" src="/assets/img/Logo_MezzoForte_black.svg" />
          <div className="full-splash-container fadein">
            <IonText color="dark"><h2 className="">VOCI DELLA TUA CITTÀ</h2></IonText>
            <div className="paragraph-container ion-margin-bottom">
              <IonText color="dark"><p>Benvenuto! Con questa applicazione potrai ascoltare e registrare i suoni della <b>Città di Follonica</b></p></IonText>
            </div>
            <IonButton color="tertiary" onClick={enter} size="large">
              {entering ? <IonSpinner name="crescent" /> : 'INIZIA'}
            </IonButton>


          </div>
        </div>) : null
      }
    </React.Fragment>
  );
};

export default Introduction;