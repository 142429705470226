import React from 'react';

interface IAppContext {
  getPositionAccess: () => boolean;
  setPositionAccess: (val: boolean) => void;
  getShowIntro: () => boolean;
  setShowIntro: (val: boolean) => void;
}

const AppContext = React.createContext<IAppContext>({
  getPositionAccess : () => false,
  setPositionAccess : () => {},
  getShowIntro: () => true,
  setShowIntro: () => {}
});

export default AppContext;